/*
  Add gap between options
*/
#kc-otp-login-form .kcInputWrapperClass {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

/*
  Option wrapper
*/
#kc-otp-login-form .kcSelectOTPListClass {
  cursor: pointer;
  padding: 1.25rem;
  border: 1px solid var(--color-secondary);
  border-radius: var(--border-radius);
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.6s;
}

#kc-otp-login-form .kcSelectOTPListClass:hover {
  border-color: var(--color-text-primary);
  opacity: 1;
}

#kc-otp-login-form .kcSelectOTPListClass:active {
  border-color: var(--color-primary);
}

/*
  Option content
*/
#kc-otp-login-form .kcSelectOTPListItemClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/*
  Option content icon
*/
#kc-otp-login-form .kcAuthenticatorOtpCircleClass:before {
  font-size: 1.5rem;
}

/*
  Option content label
*/
#kc-otp-login-form .kcSelectOTPItemHeadingClass {
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
}
