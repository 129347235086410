/*
  "Hide" period at the end of the sentence
*/
#instruction1 {
  text-align: center;
  color: transparent;
}

#instruction1 span {
  color: var(--color-text-primary);
}

/*
  "Click here" in separate line
*/
#loginRestartLink,
#loginContinueLink {
  display: block;
}
