* {
  box-sizing: border-box;
  scrollbar-width: thin;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: var(--color-background);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100vh;
  width: 100%;
  color: var(--color-text-primary);
}

#root {
  height: 100%;
  overflow-y: auto;
  padding: 4rem 2rem;
  display: grid;
  place-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

span {
  font-size: 0.875rem;
}

a {
  color: var(--color-primary);
  transition: 0.3s;
}

a:hover {
  opacity: 0.8;
}
