.input-label {
  color: var(--color-text-primary);
}

.input-label::after {
  content: ' ';
}

.input {
  font-size: 0.875rem;
  width: 100%;
  padding-inline: 1rem;
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
}
