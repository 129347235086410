/*
  Remove unnecessary margin from heading
*/
#kc-oauth > h3 {
  margin-top: 0;
}

/*
  Permissions bullet list
*/
#kc-oauth > ul {
  list-style: initial;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

/*
  Add gap between bullets
*/
#kc-oauth > ul > li {
  margin-bottom: 0.25rem;
}

/*
  Make yes/no actions full width
*/
#kc-oauth #kc-form-buttons > .kcFormButtonsWrapperClass {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
}
