/*
  Add bottom margin and gap between attempted username and "Restart login"
*/
#kc-username {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/*
  Override global font-size
*/
#reset-login * {
  font-size: 1rem;
}

/*
  Remove invisible icon which messes up alignment
*/
#reset-login .kcResetFlowIcon {
  display: none;
}

/*
  Reverse order of auth options (auth providers first, username-password second)
*/
#kc-form {
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
}

/*
  Add bottom margin to "Remember me" checkbox
*/
#kc-form-options {
  margin-bottom: 1rem;
}

/*
  Fix alignment and gap between checkbox and "Remember me"
*/
#kc-form-options > .checkbox > label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/*
  Add top margin to "Sign In" button
*/
#kc-form-buttons {
  margin-top: 1.5rem;
}

/*
  Make "Sign In" button full-width
*/
#kc-form-buttons > .button {
  width: 100%;
}

/*
  List of auth providers
*/
.login-pf-social {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/*
- Single auth provider wrapper
*/
a.zocial {
  height: 3rem;
  padding-inline: 1.375rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-secondary);
  border-radius: var(--border-radius);
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: 0.6s;
}

a.zocial:hover {
  border-color: var(--color-text-primary);
  opacity: 1;
}

.login-pf-social > li:first-child > a.zocial {
  margin-top: 0;
}

/*
- Auth provider icon and name alignment
*/
a.zocial > span {
  display: flex;
  align-items: center;
  text-align: center;
}

/*
- Auth provider icon
*/
a.zocial > span::before {
  content: '';
  min-width: 1.25rem;
  max-width: 1.25rem;
  min-height: 1.25rem;
  max-height: 1.25rem;
  margin-right: 0.75rem;
}

#zocial-google > span::before {
  background: url('../../img/google.svg') no-repeat;
  background-size: 1.25rem 1.25rem;
}

#zocial-github > span::before, #zocial-github-enterprise-server > span::before {
  background: url('../../img/github-dark-mode.svg') no-repeat;
  background-size: 1.25rem 1.25rem;
}

html.light #zocial-github > span::before, html.light #zocial-github-enterprise-server > span::before {
  background: url('../../img/github-light-mode.svg') no-repeat;
  background-size: 1.25rem 1.25rem;
}

#zocial-gitlab > span::before, #zocial-gitlab-self-managed > span::before {
  background: url('../../img/gitlab.svg') no-repeat;
  background-size: 1.25rem 1.25rem;
}

#zocial-bitbucket > span::before, #zocial-bitbucket-server > span::before {
  background: url('../../img/bitbucket.svg') no-repeat;
  background-size: 1.25rem 1.25rem;
}

#rememberMe {
  cursor: pointer;
}

/*
  Link below form submit button
*/
#try-another-way {
  display: inline-block;
  margin-top: 1.5rem;
}