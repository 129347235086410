.button {
  height: 2.5rem;
  padding-inline: 1.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.6s;
}

.button--primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.button--primary:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
}

.button--danger {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
}

.button--danger:hover {
  background-color: var(--color-danger-hover);
  border-color: var(--color-danger-hover);
}

.button--default {
  background-color: transparent;
  border-color: var(--color-secondary);
  color: var(--color-text-primary);
}

.button--default:hover {
  border-color: var(--color-text-primary);
}
