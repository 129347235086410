/*
  Add gap between options
*/
#kc-select-credential-form .kcSelectAuthListClass {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/*
  Option wrapper
*/
#kc-select-credential-form .kcSelectAuthListItemInfoClass {
  padding: 1.25rem;
  border: 1px solid var(--color-secondary);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.6s;
}

#kc-select-credential-form .kcSelectAuthListItemInfoClass:hover {
  border-color: var(--color-text-primary);
  opacity: 1;
}

/*
  Hide option icon
*/
#kc-select-credential-form .kcSelectAuthListItemLeftClass {
  display: none;
}

/*
  Add gap between option heading and description
*/
#kc-select-credential-form .kcSelectAuthListItemDescriptionClass {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/*
  Option heading
*/
#kc-select-credential-form .kcSelectAuthListItemHeadingClass > span {
  font-size: 1.125rem;
  font-weight: 500;
}

/*
  Option description
*/
#kc-select-credential-form .kcSelectAuthListItemHelpTextClass > span {
  font-size: 0.875rem;
  opacity: 0.8;
}
