/*
  Placement of items in info message wrapper
*/
#kc-info-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
}

/*
  Info message color
*/
#kc-info-message > .instruction {
  color: var(--color-text-primary);
}
