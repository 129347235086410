/*
  Placement of items in error message wrapper
*/
#kc-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*
  Error message color
*/
#kc-error-message > .instruction {
  color: var(--color-danger);
  font-size: 0.875rem;
}
