/*
  Top-level keycloakify component, container for header and form content
*/
.login-pf-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  background-color: var(--color-background);
}

@media screen and (min-width: 600px) {
  .login-pf-page {
    max-width: 530px;
    margin-inline: auto;
    padding: 3rem;
    border-color: var(--color-border);
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius);
  }
}

/* 
  Display logo in header
*/
#kc-header-wrapper {
  background-image: url('../../img/logo-dark-mode.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 2.5rem;
  width: min(100vw - 4rem, 20rem);
  background-size: contain;
}

html.light #kc-header-wrapper {
  background-image: url('../../img/logo-light-mode.svg');
}

/*
  Hide default header content
*/
#kc-header-wrapper > span {
  display: none;
}

/*
  Hide Keycloak page title
*/
#kc-page-title {
  display: none;
}

/* 
  Make card content full-width
*/
.card-pf {
  width: 100%;
}

/*
  Add extra margin between label-input groups
*/
.form-group:not(:last-child) {
  margin-bottom: 1rem;
}

/*
  Hide list of locales
*/
#kc-locale {
  display: none;
}

/*
  Validation error
*/
.kcInputErrorMessageClass * {
  font-family: var(--font-family-utility);
}