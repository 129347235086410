:root {
  --font-family-primary: 'Berkeley Mono', monospace;
  --font-family-utility: 'Inter', sans-serif;

  --border-radius: 3px;
}

html {
  --color-primary: #007fff;
  --color-primary-hover: #3d9eff;
  --color-secondary: #585858;
  --color-tertiary: #161616;
  --color-success: #2ecc71;
  --color-success-hover: #54d98c;
  --color-danger: #e74c3c;
  --color-danger-hover: #ed7669;
  --color-warning: #f1c40f;
  --color-warning-hover: #f4d03f;
  --color-background: #0a0a0a;
  --color-text-primary: #fff;
  --color-white: #fff;
  --color-border: #585858;
}

html.light {
  --color-primary: #007fff;
  --color-primary-hover: #005fbe;
  --color-secondary: #dedede;
  --color-tertiary: #f7f7f7;
  --color-success: #1abc9c;
  --color-success-hover: #12806a;
  --color-danger: #e74c3c;
  --color-danger-hover: #c72918;
  --color-warning: #e67e22;
  --color-warning-hover: #b05d14;
  --color-background: #fff;
  --color-text-primary: #121212;
  --color-white: #fff;
  --color-border: #dedede;
}