/*
  Add top margin to registration link
*/
#kc-registration {
  margin-top: 2rem;
}

/*
  Add margin between "New user?" and "Register"
*/
#kc-registration > span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
