/*
  2FA instructions numbered list
*/
#kc-totp-settings {
  padding: 0 1rem;
  margin: 0;
  margin-bottom: 2rem;
}

/*
  2FA supported apps bulleted sublist
*/
#kc-totp-supported-apps {
  list-style: initial;
  padding: 0 1rem;
}

/*
  Make submit button full width
*/
#saveTOTPBtn {
  width: 100%;
}
