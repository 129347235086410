/*
  Terms of service content
*/
#kc-terms-text {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  height: calc(100% - 7.25rem);
  padding: 1rem 2rem;
  background: var(--color-background);
}

#kc-terms-text * {
  word-wrap: break-word;
}

/*
  Terms of service user actions container
*/
#kc-terms-text ~ .form-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 2rem;
  background-color: var(--color-background);
  border-top: 1px solid var(--color-border);
}
