/*
  Make actions full width
*/
#updateProfile,
#linkAccount {
  width: 100%;
}

/*
  Add gap between actions
*/
#updateProfile {
  margin-bottom: 1rem;
}
