/*
  Keycloakify adds these class names to alert elements, we need to apply styling
*/

.alert {
  color: var(--alert-style);
  background-color: var(--color-tertiary);
  font-weight: 500;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--alert-style);
  border-radius: var(--border-radius);
  padding: 1rem 1.25rem;
  margin-bottom: 2rem;
  margin-inline: auto;
  animation: alert-animation 0.2s;
}

.alert * {  
  font-family: var(--font-family-utility);
}

.alert-success {
  --alert-style: var(--color-success);
}

.alert-info {
  --alert-style: var(--color-text-primary);
}

.alert-warning {
  --alert-style: var(--color-warning);
}

.alert-error {
  --alert-style: var(--color-danger);
}

@keyframes alert-animation {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
